import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { GeneralLedgerView } from '../models/GeneralLedgerView';
import { WorkflowForm } from '../models/WorkflowForm';
import { LookupServices } from './lookup.service';
import { map } from 'rxjs/operators';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ClientsService } from './clients.service';
import { GlSegmentation, glSegmentations } from 'app/models/segment-labeling-gl-strings/GlSegmentation';
import { ValueFormatterParams } from 'ag-grid-community';
import { DeviceDetectorService } from 'ngx-device-detector';

declare var moment: any;

@Injectable({
	providedIn: 'root',
})
export class UtilityService {
	constructor(
		private readonly lookupService: LookupServices,
		private readonly clientService: ClientsService,
		private readonly deviceService: DeviceDetectorService
	) {}

	getGlSegmentation(clientId: any): GlSegmentation {
		let segmentation = glSegmentations.find((t) => t.clientId == clientId);
		if (segmentation) {
			return segmentation;
		}
		return glSegmentations.find((t) => t.clientId == 'default');
	}

	isNullOrEmpty(myString: string): boolean {
		return myString == null || myString.trim() === '';
	}

	formatCurrency(currency: number) {
		try {
			let converted = Number(currency);
			if (converted || converted == 0) {
				let changedVal = converted;
				if (converted < 0) {
					changedVal *= -1;
				}
				let value = Number(changedVal.toFixed(2)).toLocaleString();
				let checkFinal = value.split('.');
				if (checkFinal.length > 1) {
					if (checkFinal[1].length == 1) {
						value += '0';
					}
				}
				if (converted >= 0) {
					return `$${value}`;
				} else {
					return `($${value})`;
				}
			}
		} catch (e) {
			console.log(e);
		}
		return '--';
	}

	formatCurrencyWithoutDollarSign(currency: number) {
		try {
			let converted = Number(currency);
			if (converted || converted == 0) {
				let changedVal = converted;
				if (converted < 0) {
					changedVal *= -1;
				}
				let value = Number(changedVal.toFixed(2)).toLocaleString();
				let checkFinal = value.split('.');
				if (checkFinal.length > 1) {
					if (checkFinal[1].length == 1) {
						value += '0';
					}
				}
				if (converted >= 0) {
					return `${value}`;
				} else {
					return `(${value})`;
				}
			}
		} catch (e) {
			console.log(e);
		}
		return '--';
	}

	formatDate(val: Date) {
		return moment.utc(val).format('MM/DD/YYYY');
	}

	removeNumericFormatting(amount: any) {
		if (amount) {
			if (amount !== '' || amount !== undefined || amount !== 0 || amount !== '0' || amount !== null) {
				amount = amount.toString();
				while (amount.indexOf(',') >= 0) {
					amount = amount.replace(',', '');
				}
				return amount;
			}
		} else {
			return amount;
		}
	}

	formatNumeric(amount: any) {
		let reg = '/B(?=(d{3})+(?!d))/g';
		if (amount || amount == 0) {
			if (amount !== '' || amount !== undefined || amount !== 0 || amount !== '0' || amount !== null) {
				amount = amount.toString().replace('$', '');
				return amount.toString().replace(reg, ',');
			}
		} else {
			return amount;
		}
	}

	truncate(str, n) {
		if (str) {
			return str.length > n ? str.substr(0, n - 1) + '...' : str;
		}

		return str;
	}

	isApprover(workflow: any, userId: string): boolean {
		if (workflow && workflow?.currentApprovers?.length > 0 && workflow.workflowStep !== 'Completed') {
			const item = workflow.currentApprovers.find((t) => t.userId == userId && !t.hasApproved);
			return item != null || item != undefined;
		} else {
			return false;
		}
	}

	hasApproved(workflow: any, userId: string): boolean {
		if (workflow && workflow?.currentApprovers?.length > 0 && workflow.workflowStep !== 'Completed') {
			const item = workflow.currentApprovers.find((t) => t.userId == userId);
			return item?.hasApproved;
		} else {
			return false;
		}
	}

	getFiscalYearFromDate(date: Date) {
		let beginMonth = this.getFiscalYearMonthBegin();
		let currentMonth = new Date(date).getMonth() + 1;
		if (currentMonth >= beginMonth && beginMonth !== 1) {
			return new Date(date).getFullYear() + 1;
		}
		return new Date(date).getFullYear();
	}

	getFiscalYear(month, year): number {
		if (month >= 9) {
			return year + 1;
		}
		return year;
	}

	getEffectiveYear(month, year) {
		let beginMonth = this.getFiscalYearMonthBegin();
		if (beginMonth == 1) {
			return year;
		}

		if (beginMonth <= month) {
			return year - 1;
		}
		return year;
	}

	getNextFiscalYear(): number[] {
		let date = new Date();
		let years = [];

		if (date.getMonth() >= 2) {
			years.push(date.getFullYear() + 1);
			return years;
		}
		years.push(date.getFullYear());
		return years;
	}

	currentFiscalYear(startAt = 1, endAt = 1): number[] {
		return this.currentFiscalYearDdl(new Date().getFullYear() - startAt, new Date().getFullYear() + endAt);
	}

	currentFiscalYearDdl(startYear: number = 2020, endYearToHave: number = -1): number[] {
		let currentYear = new Date().getFullYear() + 1;
		let currentDate = new Date();
		let monthBegin = this.getFiscalYearMonthBegin();
		let currentMonth = currentDate.getMonth() + 1;
		if (currentMonth >= monthBegin && monthBegin !== 1) {
			currentYear++;
		}

		if (endYearToHave > 0) {
			currentYear = endYearToHave;
		}

		let years = [];

		while (startYear <= currentYear) {
			years.push(startYear);
			startYear++;
		}

		return years;
	}

	objectToQueryString(obj: Record<string, any>): string {
		let str: string[] = [];
		for (let p in obj) {
			if (obj.hasOwnProperty(p) && obj[p] !== null && obj[p] !== undefined) {
				str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
			}
		}
		return str.join('&');
	}

	glDisplay(gl: GeneralLedgerView) {
		let glSettings = localStorage.getItem('GLDisplay');
		if (glSettings && glSettings == 'Name') {
			return gl.name;
		} else {
			return `${gl.id} - ${gl.name}`;
		}
	}

	canSendForReview(item: WorkflowForm, userId: string) {
		return !this.hasApproved(item, userId) && this.isApprover(item, userId);
	}

	getRandomText(length) {
		const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.match(/./g);
		let text = '';

		for (let i = 0; i < length; i++) {
			text += charset[Math.floor(this.cryptoRandomGenerator() * charset.length)];
		}
		return text;
	}

	cryptoRandomGenerator() {
		const typedArray = new Uint8Array(1);
		const randomValue = crypto.getRandomValues(typedArray)[0];
		const randomFloat = randomValue / Math.pow(2, 8);
		return randomFloat;
	}

	scrollToTop() {
		const element = document.getElementById('navigation');
		if (element) {
			element.scrollIntoView();
		}
	}

	testId(id: string) {
		if (id == 'create' || id == 'view' || id == 'edit') {
			return null;
		}

		if (id && id.indexOf('?') >= 0) {
			id = id.split('?')[0];
		}

		return id;
	}

	getMonthNameFromNumber(monthNumber: any): string {
		let monthNames = [
			'January',
			'February',
			'March',
			'April',
			'May',
			'June',
			'July',
			'August',
			'September',
			'October',
			'November',
			'December',
			'Period 13',
		];
		return monthNames[monthNumber - 1];
	}

	getFinancialYears(clientId: string): Observable<number[]> {
		return this.lookupService.getYears(clientId).pipe(
			map((res) => {
				return res;
			})
		);
	}

	getMonthsForYear(clientId: string, year: any): Observable<any[]> {
		return this.lookupService.getMonthsForYear(clientId, year).pipe(
			map((res) => {
				let months = [];
				if (res) {
					res.forEach((element) => {
						months.push({ id: element, name: this.getMonthNameFromNumber(element) });
					});
				}
				return months;
			})
		);
	}

	getMonths() {
		return [
			{
				name: 'January',
				value: 1,
			},
			{
				name: 'February',
				value: 2,
			},
			{
				name: 'March',
				value: 3,
			},
			{
				name: 'April',
				value: 4,
			},
			{
				name: 'May',
				value: 5,
			},
			{
				name: 'June',
				value: 6,
			},
			{
				name: 'July',
				value: 7,
			},
			{
				name: 'August',
				value: 8,
			},
			{
				name: 'September',
				value: 9,
			},
			{
				name: 'October',
				value: 10,
			},
			{
				name: 'November',
				value: 11,
			},
			{
				name: 'December',
				value: 12,
			},
		];
	}

	getUsState() {
		return [
			{
				name: 'Alabama',
				abbreviation: 'AL',
			},
			{
				name: 'Alaska',
				abbreviation: 'AK',
			},
			{
				name: 'American Samoa',
				abbreviation: 'AS',
			},
			{
				name: 'Arizona',
				abbreviation: 'AZ',
			},
			{
				name: 'Arkansas',
				abbreviation: 'AR',
			},
			{
				name: 'California',
				abbreviation: 'CA',
			},
			{
				name: 'Colorado',
				abbreviation: 'CO',
			},
			{
				name: 'Connecticut',
				abbreviation: 'CT',
			},
			{
				name: 'Delaware',
				abbreviation: 'DE',
			},
			{
				name: 'District Of Columbia',
				abbreviation: 'DC',
			},
			{
				name: 'Federated States Of Micronesia',
				abbreviation: 'FM',
			},
			{
				name: 'Florida',
				abbreviation: 'FL',
			},
			{
				name: 'Georgia',
				abbreviation: 'GA',
			},
			{
				name: 'Guam',
				abbreviation: 'GU',
			},
			{
				name: 'Hawaii',
				abbreviation: 'HI',
			},
			{
				name: 'Idaho',
				abbreviation: 'ID',
			},
			{
				name: 'Illinois',
				abbreviation: 'IL',
			},
			{
				name: 'Indiana',
				abbreviation: 'IN',
			},
			{
				name: 'Iowa',
				abbreviation: 'IA',
			},
			{
				name: 'Kansas',
				abbreviation: 'KS',
			},
			{
				name: 'Kentucky',
				abbreviation: 'KY',
			},
			{
				name: 'Louisiana',
				abbreviation: 'LA',
			},
			{
				name: 'Maine',
				abbreviation: 'ME',
			},
			{
				name: 'Marshall Islands',
				abbreviation: 'MH',
			},
			{
				name: 'Maryland',
				abbreviation: 'MD',
			},
			{
				name: 'Massachusetts',
				abbreviation: 'MA',
			},
			{
				name: 'Michigan',
				abbreviation: 'MI',
			},
			{
				name: 'Minnesota',
				abbreviation: 'MN',
			},
			{
				name: 'Mississippi',
				abbreviation: 'MS',
			},
			{
				name: 'Missouri',
				abbreviation: 'MO',
			},
			{
				name: 'Montana',
				abbreviation: 'MT',
			},
			{
				name: 'Nebraska',
				abbreviation: 'NE',
			},
			{
				name: 'Nevada',
				abbreviation: 'NV',
			},
			{
				name: 'New Hampshire',
				abbreviation: 'NH',
			},
			{
				name: 'New Jersey',
				abbreviation: 'NJ',
			},
			{
				name: 'New Mexico',
				abbreviation: 'NM',
			},
			{
				name: 'New York',
				abbreviation: 'NY',
			},
			{
				name: 'North Carolina',
				abbreviation: 'NC',
			},
			{
				name: 'North Dakota',
				abbreviation: 'ND',
			},
			{
				name: 'Northern Mariana Islands',
				abbreviation: 'MP',
			},
			{
				name: 'Ohio',
				abbreviation: 'OH',
			},
			{
				name: 'Oklahoma',
				abbreviation: 'OK',
			},
			{
				name: 'Oregon',
				abbreviation: 'OR',
			},
			{
				name: 'Palau',
				abbreviation: 'PW',
			},
			{
				name: 'Pennsylvania',
				abbreviation: 'PA',
			},
			{
				name: 'Puerto Rico',
				abbreviation: 'PR',
			},
			{
				name: 'Rhode Island',
				abbreviation: 'RI',
			},
			{
				name: 'South Carolina',
				abbreviation: 'SC',
			},
			{
				name: 'South Dakota',
				abbreviation: 'SD',
			},
			{
				name: 'Tennessee',
				abbreviation: 'TN',
			},
			{
				name: 'Texas',
				abbreviation: 'TX',
			},
			{
				name: 'Utah',
				abbreviation: 'UT',
			},
			{
				name: 'Vermont',
				abbreviation: 'VT',
			},
			{
				name: 'Virgin Islands',
				abbreviation: 'VI',
			},
			{
				name: 'Virginia',
				abbreviation: 'VA',
			},
			{
				name: 'Washington',
				abbreviation: 'WA',
			},
			{
				name: 'West Virginia',
				abbreviation: 'WV',
			},
			{
				name: 'Wisconsin',
				abbreviation: 'WI',
			},
			{
				name: 'Wyoming',
				abbreviation: 'WY',
			},
		];
	}

	getFloridaCounty() {
		return [
			{
				name: 'Alachua County',
			},
			{
				name: 'Baker County',
			},
			{
				name: 'Bay County',
			},
			{
				name: 'Bradford County',
			},
			{
				name: 'Brevard County',
			},
			{
				name: 'Broward County',
			},
			{
				name: 'Calhoun County',
			},
			{
				name: 'Charlotte County',
			},
			{
				name: 'Citrus County',
			},
			{
				name: 'Clay County',
			},
			{
				name: 'Collier County',
			},
			{
				name: 'Columbia County',
			},
			{
				name: 'DeSoto County',
			},
			{
				name: 'Dixie County',
			},
			{
				name: 'Duval County',
			},
			{
				name: 'Escambia County',
			},
			{
				name: 'Flagler County',
			},
			{
				name: 'Franklin County',
			},
			{
				name: 'Gadsden County',
			},
			{
				name: 'Gilchrist County',
			},
			{
				name: 'Glades County',
			},
			{
				name: 'Gulf County',
			},
			{
				name: 'Hamilton County',
			},
			{
				name: 'Hardee County',
			},
			{
				name: 'Hendry County',
			},
			{
				name: 'Hernando County',
			},
			{
				name: 'Highlands County',
			},
			{
				name: 'Hillsborough County',
			},
			{
				name: 'Holmes County',
			},
			{
				name: 'Indian River County',
			},
			{
				name: 'Jackson County',
			},
			{
				name: 'Jefferson County',
			},
			{
				name: 'Lafayette County',
			},
			{
				name: 'Lake County',
			},
			{
				name: 'Lee County',
			},
			{
				name: 'Leon County',
			},
			{
				name: 'Levy County',
			},
			{
				name: 'Liberty County',
			},
			{
				name: 'Madison County',
			},
			{
				name: 'Manatee County',
			},
			{
				name: 'Marion County',
			},
			{
				name: 'Martin County',
			},
			{
				name: 'Miami-Dade County',
			},
			{
				name: 'Monroe County',
			},
			{
				name: 'Nassau County',
			},
			{
				name: 'Okaloosa County',
			},
			{
				name: 'Okeechobee County',
			},
			{
				name: 'Orange County',
			},
			{
				name: 'Osceola County',
			},
			{
				name: 'Palm Beach County',
			},
			{
				name: 'Pasco County',
			},
			{
				name: 'Pinellas County',
			},
			{
				name: 'Polk County',
			},
			{
				name: 'Putnam County',
			},
			{
				name: 'Santa Rosa County',
			},
			{
				name: 'Sarasota County',
			},
			{
				name: 'Seminole County',
			},
			{
				name: 'St. Johns County',
			},
			{
				name: 'St. Lucie County',
			},
			{
				name: 'Sumter County',
			},
			{
				name: 'Suwannee County',
			},
			{
				name: 'Taylor County',
			},
			{
				name: 'Union County',
			},
			{
				name: 'Volusia County',
			},
			{
				name: 'Wakulla County',
			},
			{
				name: 'Walton County',
			},
			{
				name: 'Washington County',
			},
		];
	}

	autoPayEnabled(clientId: string): Observable<{ displayAutoPay: boolean; autoPayDefaultValue: boolean }> {
		let purchasingPaymentsEnabled = localStorage.getItem('PurchasingPaymentsEnabled');
		if (purchasingPaymentsEnabled == null || (purchasingPaymentsEnabled == 'null' && clientId)) {
			return this.clientService.getClientFeatures(clientId).pipe(
				map((res) => {
					localStorage.setItem('PurchasingPaymentsEnabled', res.purchasingPaymentsEnabled);
					let displayAutoPay = res.purchasingPaymentsEnabled == 'true';
					let autoPayDefaultValue = displayAutoPay ? this.getAutoPayDefaultValue() : false;
					return { displayAutoPay, autoPayDefaultValue };
				})
			);
		} else {
			let displayAutoPay = purchasingPaymentsEnabled == 'true';
			let autoPayDefaultValue = displayAutoPay ? this.getAutoPayDefaultValue() : false;
			return of({ displayAutoPay, autoPayDefaultValue });
		}
	}

	private getAutoPayDefaultValue(): boolean {
		let purchasingPaymentsDefaultValue = localStorage.getItem('PurchasingPaymentsDefaultValue');
		return purchasingPaymentsDefaultValue ? purchasingPaymentsDefaultValue == 'true' : false;
	}

	round(num: number) {
		let m = Number((Math.abs(num) * 100).toPrecision(15));
		return (Math.round(m) / 100) * Math.sign(num);
	}

	decimalPlaces(num: number) {
		var match = ('' + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
		if (!match) {
			return 0;
		}
		return Math.max(
			0,
			// Number of digits right of decimal point.
			(match[1] ? match[1].length : 0) -
				// Adjust for scientific notation.
				(match[2] ? +match[2] : 0)
		);
	}

	getCurrentStep(item: WorkflowForm) {
		let currentStep = item?.workflowSteps?.find((t) => t.isCurrent);
		let stepName = '';
		if (currentStep != null) {
			stepName = currentStep.name;
		}

		return stepName;
	}

	getEmployeeForm(fb: FormBuilder): FormGroup {
		return fb.group({
			firstName: [null, [Validators.required]],
			lastName: [null],
			title: [null, [Validators.required]],
			departmentId: [null],
			isSalary: [true, [Validators.required]],
			allocationPercentage: [100, [Validators.required]],
			payRate: [null, [Validators.required]],
			otherRateChanges: [null],
			overTimeAllocated: [null],
			groupInsurance: [null],
			retirementType: [null],
			hourPerYearBase: [2080, [Validators.required]],
			specialPay: [null],
			healthType: [null],
			hasDental: [null],
			hasLife: [null],
			workersCompRateType: [null],
			id: [null],
			account: [null],
			pbaStep: [0],
			longevityPay: [null],
			meritPay: [null],
			hasCostOfLiving: [false],
			wages: [],
			name: [],
		});
	}

	getDatesOfAMonth(month, year) {
		let days = [];
		let daysInMonth = moment(`${year}-${month}`, 'YYYY-MM').daysInMonth();
		for (let i = 1; i <= daysInMonth; i++) {
			const date = moment(`${year}-${month}-${i}`, 'YYYY-MM-DD').format('YYYY-MM-DD');
			days.push(date);
		}
		return days;
	}

	convertToDate(dateStr: any) {
		if (!dateStr || dateStr == '0001-01-01T00:00:00Z') {
			return '';
		}
		if (dateStr instanceof Date) {
			return new Date(
				dateStr.getFullYear(),
				dateStr.getMonth(),
				dateStr.getDate(),
				dateStr.getHours(),
				dateStr.getMinutes(),
				dateStr.getSeconds(),
				dateStr.getMilliseconds()
			);
		}

		let date = new Date(dateStr);
		return moment(date).format('MM/DD/YYYY');
	}

	convertToDateFromUTC(dateStr) {
		if (!dateStr) {
			return '';
		}
		if (dateStr instanceof Date) {
			return new Date(
				dateStr.getFullYear(),
				dateStr.getMonth(),
				dateStr.getDate(),
				dateStr.getHours(),
				dateStr.getMinutes(),
				dateStr.getSeconds(),
				dateStr.getMilliseconds()
			);
		}

		return moment(dateStr).utc().format('MM/DD/yyyy');
	}

	formatPhone(phone) {
		if (!phone) {
			return '';
		}
		return phone.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
	}

	getBenefitsDisplayValue(method: any, contribution: any) {
		if (contribution && method) {
			return method == 1 || method == 20 || method == 40 ? `${this.formatCurrency(contribution)}` : `${contribution}%`;
		} else {
			return '';
		}
	}

	private readonly monthNames = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
		'Period 13',
	];

	getMonthName(month: number) {
		if (month <= 0) {
			month = 1;
		} else if (month > 13) {
			month = 13;
		}
		return this.monthNames[month - 1];
	}

	retrieveConfiguration(clientId: string, callback: () => void, override: boolean = true) {
		if (!localStorage.getItem('FiscalYearMonthBegin')) {
			override = true;
		}
		if (override) {
			localStorage.removeItem('GLDisplay');
			localStorage.removeItem('PurchasingPaymentsEnabled');
			localStorage.removeItem('PurchasingPaymentsDefaultValue');
			localStorage.removeItem('FiscalYearMonthBegin');
			localStorage.removeItem('ReimbursementMileageRate');
			localStorage.removeItem('HasParentFund');
			localStorage.removeItem('HasParentDepartment');
			localStorage.removeItem('HasFSLineItem');
			let clientConfig = this.clientService.getClientFeatures(clientId).toPromise();
			let allConfigs = this.clientService.getClientConfigurations(clientId).toPromise();
			Promise.all([clientConfig, allConfigs]).then((data) => {
				let clientConfig = data[0];
				let configs = data[1];
				localStorage.setItem('PurchasingPaymentsEnabled', clientConfig.purchasingPaymentsEnabled ?? 'false');
				localStorage.setItem('PurchasingPaymentsDefaultValue', clientConfig.purchasingPaymentsDefaultValue ?? 'false');
				localStorage.setItem('FiscalYearMonthBegin', clientConfig.fiscalYearMonthBegin.toString());
				localStorage.setItem('BenefitsEnrollmentPeriod', clientConfig.benefitsEnrollmentPeriod);
				localStorage.setItem('ClientJobPortalUrl', clientConfig.clientJobPortalUrl);
				localStorage.setItem('ElaboratedJobApplicantDetails', clientConfig.elaboratedJobApplicantDetails);
				localStorage.setItem('IsTimesheetIPRestricted', clientConfig.isTimesheetIPRestricted ?? 'false');
				localStorage.setItem('BenefitsEnrollmentMessage', clientConfig.benefitsEnrollmentMessage);
				localStorage.setItem('ReimbursementMileageRate', clientConfig.reimbursementMileageRate);
				localStorage.setItem('HasParentFund', clientConfig.hasParentFund ?? 'false');
				localStorage.setItem('HasParentDepartment', clientConfig.hasParentDepartment ?? 'false');
				localStorage.setItem('HasFSLineItem', clientConfig.hasFSLineItem ?? 'false');
				if (configs && configs.length > 0) {
					let glConfig = configs.find((t) => t.configurationType == 'GLDisplay');
					if (glConfig && glConfig.value) {
						localStorage.setItem('GLDisplay', glConfig.value);
					}
				}

				if (callback) {
					callback();
				}
			});
		}
	}

	getFiscalYearMonthBegin(): number {
		let fiscalYearMonthBegin = localStorage.getItem('FiscalYearMonthBegin');
		if (fiscalYearMonthBegin) {
			return parseInt(fiscalYearMonthBegin);
		} else {
			return 10; // defaulting to October
		}
	}

	booleanStatusFormatter = (params) => {
		return params.value == true ? 'Active' : 'Inactive';
	};

	defaultExcelExportParams = {
		processCellCallback: (params) => {
			const colDef = params.column.getColDef();
			// try to reuse valueFormatter from the colDef
			if (colDef.valueFormatter) {
				const valueFormatterParams: ValueFormatterParams = {
					...params,
					data: params.node.data,
					node: params.node!,
					colDef: params.column.getColDef(),
				};
				return colDef.valueFormatter(valueFormatterParams);
			}
			return params.value;
		},
	};

	excelStyles = [
		{
			id: 'numberType',
			numberFormat: {
				format: '0',
			},
		},
		{
			id: 'currencyFormat',
			numberFormat: {
				format: '$#,##0.00;$[red](#,##0.00)',
			},
		},
		{
			id: 'negativeInBrackets',
			numberFormat: {
				format: '$[blue] #,##0;$ [red](#,##0)',
			},
		},
		{
			id: 'booleanType',
			dataType: 'Boolean',
		},
		{
			id: 'stringType',
			dataType: 'String',
		},
		{
			id: 'dateType',
			dataType: 'DateTime',
		},
	];

	setEffectiveCalendar(e: any, month: any, fiscalYear: any) {
		e.val('');
		if (!!month) {
			e.prop('disabled', false);
			let year = this.getEffectiveYear(month, fiscalYear);
			let effectiveStartDate = new Date(year, month - 1);
			let effectiveLastDate = new Date(effectiveStartDate.getFullYear(), effectiveStartDate.getMonth() + 1, 0);
			$(function () {
				if (e.hasClass('hasDatepicker')) {
					e.datepicker('option', {
						dateFormat: 'mm/dd/yy',
						minDate: effectiveStartDate,
						maxDate: effectiveLastDate,
						setDate: effectiveStartDate,
					});
				} else {
					e.datepicker({
						dateFormat: 'mm/dd/yy',
						minDate: effectiveStartDate,
						maxDate: effectiveLastDate,
						setDate: effectiveStartDate,
					});
				}
			});
		} else {
			e.prop('disabled', true);
			e.val('');
		}
	}

	verifyEffectiveDate(effectiveDate: any, fiscalmonth: any, fiscalYear: any) {
		let year = this.getEffectiveYear(fiscalmonth, fiscalYear);
		if (effectiveDate) {
			let date = new Date(effectiveDate);
			if (date.getFullYear() != parseInt(year) || date.getMonth() + 1 != parseInt(fiscalmonth)) {
				return false;
			}
			return true;
		}
	}

	getFormValidationErrors(form: FormGroup) {
		Object.keys(form.controls).forEach((key) => {
			const controlErrors: ValidationErrors = form.get(key).errors;
			if (controlErrors != null) {
				Object.keys(controlErrors).forEach((keyError) => {
					console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
				});
			}
		});
	}

	formateJson(obj: any[]) {
		let newList = [];
		obj.forEach((elsement) => {
			let key,
				keys = Object.keys(elsement);
			let n = keys.length;
			let newobj = {};
			while (n--) {
				key = keys[n];
				newobj[key.toLowerCase()] = elsement[key];
			}
			newList.push(newobj);
		});
		return newList;
	}

	setReactiveForm(employeeForm: FormGroup, item: any) {
		Object.keys(item).forEach((key) => {
			if (employeeForm.controls[key]) {
				employeeForm.get(key).setValue(item[key]);
			}
		});
		return employeeForm;
	}

	getFormsAll() {
		let forms = [
			{ id: 'gl-account', name: 'Account Management' },
			{ id: 'bank-reconciliation', name: 'Bank Reconciliation' },
			{ id: 'bid-form', name: 'Bid Form' },
			{ id: 'budget-transfer', name: 'Budget Transfer' },
			{ id: 'budget-amend', name: 'Budget Amendment' },
			{ id: 'business-license', name: 'Business License' },
			{ id: 'addition-form', name: 'Capital Assets Addition' },
			{ id: 'disposal-form', name: 'Capital Assets Disposal' },
			{ id: 'transfer-form', name: 'Capital Assets Transfer' },
			{ id: 'cashiering-report', name: 'Cashiering Form' },
			{ id: 'change-order', name: 'Change Order Form' },
			{ id: 'check-request', name: 'Check Request Form' },
			{ id: 'contract-approval', name: 'Contract Approval Form' },
			{ id: 'cc-approval', name: 'Credit Card Approval (AMSkills)' },
			{ id: 'customer-form', name: 'Customer Form' },
			{ id: 'depreciation-schedule-form', name: 'Depreciation Schedule Form' },
			{ id: 'department-addition-form', name: 'Department Addition Form' },
			{ id: 'fund-addition-form', name: 'Fund Addition Form' },
			{ id: 'customer-invoice-form', name: 'Invoice Form' },
			{ id: 'invoice-approval', name: 'Invoice Approval (AMSkills)' },
			{ id: 'ap-invoice', name: 'Invoice Approval Form w/PO' },
			{ id: 'journal-entry', name: 'Journal Entry Form' },
			{ id: 'lease-evaluation', name: 'Lease Evaluation Form' },
			{ id: 'monthly-timesheet', name: 'Monthly Time Sheet Form' },
			{ id: 'newproject', name: 'New Project Form' },
			{ id: 'capital-budget', name: 'Next Year Capital Budget' },
			{ id: 'operating-expense-budget', name: 'Next Year Operating Expense Budget' },
			{ id: 'dpt-exp-budget', name: 'Next Year Operating Budget' },
			{ id: 'fund-rev-budget', name: 'Next Year Revenue Budget' },
			{ id: 'open-close-period', name: 'Open Close Period Form' },
			{ id: 'p-card', name: 'P Card Form' },
			{ id: 'personnel', name: 'Personnel Form' },
			{ id: 'project-close-form', name: 'Project Close Form (project mgt)' },
			{ id: 'project-grant-form', name: 'Project & Grant Form (MB)' },
			{ id: 'project-grant-task', name: 'Project & Grant Task (MB)' },
			{ id: 'project-grant-close-form', name: 'Project & Grant Close Form (MB)' },
			{ id: 'project-grant-reopen-form', name: 'Project & Grant Reopen Form (MB)' },
			{ id: 'project-mgt', name: 'Project Request (project mgt)' },
			{ id: 'project-open-form', name: 'Project Open Form (project mgt)' },
			{ id: 'funding-increase-form', name: 'Project Funding Increase Form (project mgt)' },
			{ id: 'payment-approval-form', name: 'Project Payment Approval Form (project mgt)' },
			{ id: 'project-mgt', name: 'Project Request (project mgt)' },
			{ id: 'purchasing', name: 'Purchase Requisition' },
			{ id: 'po-rollover-form', name: 'PO Rollover Form' },
			{ id: 'quick-invoice-form', name: 'Quick Invoice Form' },
			{ id: 'quick-pay', name: 'Quick Pay Form' },
			{ id: 'receipt-verification', name: 'Receipt Verification Form' },
			{ id: 'support-request', name: 'Support Request Form' },
			{ id: 'timesheet', name: 'Time Sheet Form' },
			{ id: 'vendor-form', name: 'Vendor Form' },
			{ id: 'void-reissue-form', name: 'Void Reissue Form' },
			{ id: 'job-application', name: 'Job Application Form' },
			{ id: 'employee-action-form', name: 'Employee Action Form' },
			{ id: 'time-off-request-form', name: 'Time Off Request Form' },
			{ id: 'weekly-timesheet', name: 'Weekly Time Sheet Form' },
			{ id: 'performance-evaluation', name: 'Performance Evaluation Form' },
			{ id: 'benefit-enrolment-form', name: 'Benefit Enrolment Form' },
			{ id: 'cash-receipt-form', name: 'Cash Receipt Form' },
			{ id: 'individual-payee-form', name: 'Individual Payee Form' },
			{ id: 'individual-payment-form', name: 'Individual Payment Form' },
			{ id: 'new-capital-lease-form', name: 'New Capital Lease Form' },
			{ id: 'user-form', name: 'User Form' },
			{ id: 'lease-modification-form', name: 'Lease Modification Form' },
			{ id: 'implementation-billing-ar', name: 'Billing & AR Implementation' },
			{ id: 'new-hire-form', name: 'New Hire Form' },
			{ id: 'implementation-general-ledger', name: 'General Ledger Implementation' },
			{ id: 'implementation-purchasing', name: 'Purchasing Implementation' },
			{ id: 'leave-balance-adjustment', name: 'Leave Balance Adjustment Form' },
			{ id: 'implementation-payment', name: 'Payment Implementation' },
			{ id: 'customer-refund-form', name: 'Customer Refund Form' },
			{ id: 'workflow-group-form', name: 'Workflow Group Form' },
			{ id: 'customer-invoice-adjustment-form', name: 'Invoice Adjustment Form' },
			{ id: 'timesheet-approval-form', name: 'TimeSheet Approval Form' },
			{ id: 'implementation-hr', name: 'Implementation HR' },
			{ id: 'travel-pre-approval', name: 'Travel Pre Approval' },
			{ id: 'travel-reimbursement', name: 'Travel Reimbursement' },
			{ id: 'special-pay-form', name: 'Special Pay Form' },
			{ id: 'tuition-reimbursement', name: 'Tuition Reimbursement' },
			{ id: 'purchasing-employee-form', name: 'Employee Form' },
			{ id: 'taxing-jurisdiction-form', name: 'Taxing Jurisdiction Form' },
			{ id: 'new-customer-form', name: 'New Customer Form' },
			{ id: 'new-property-form', name: 'New Property Form' },
			{ id: 'new-customer-account-form', name: 'New Customer Account Form' },
			{ id: 'implementation-payroll', name: 'Implementation Payroll' },
			{ id: 'leave-balance-payout-form', name: 'Leave Balance Payout Form' },
			{ id: 'other-tuition-reimbursement', name: 'Other Tuition Reimbursement' },
			{ id: 'other-tuition-reimbursement-payment', name: 'Other Tuition Reimbursement Payment' },
			{ id: 'vendor-information-change-request', name: 'Vendor Information Change Request Form' },
		];
		return forms;
	}

	formatNumber(number) {
		let reg = /(d)(?=(d{3})+(?!d))/g;
		let value = Number(Math.abs(number)).toFixed(2).toString().replace(reg, '$1,');
		return value;
	}

	formatNumberWithNegative(number) {
		let reg = /(d)(?=(d{3})+(?!d))/g;
		let value = Number(number).toFixed(2).toString().replace(reg, '$1,');
		return value;
	}

	getOrganizationId(glAccount: string): number {
		let organizationId = 0;
		if (glAccount == null || glAccount == undefined || glAccount == '') {
			return 0;
		}
		const glArray = glAccount.split('.');
		if (glArray.length > 0) {
			organizationId = Number(glArray[1]);
		}
		return organizationId;
	}

	getEntityId(glAccount: string): number {
		let entityId = 0;
		if (glAccount == null || glAccount == undefined || glAccount == '') {
			return 0;
		}
		const glArray = glAccount.split('.');
		if (glArray.length > 0) {
			entityId = Number(glArray[0]);
		}
		return entityId;
	}

	benefitsEnrollmentPeriod() {
		let benefitsEnrollmentPeriod = localStorage.getItem('BenefitsEnrollmentPeriod');
		return benefitsEnrollmentPeriod == 'null' ? 'February 25, 2022' : benefitsEnrollmentPeriod;
	}

	clientJobPortalUrl() {
		return localStorage.getItem('ClientJobPortalUrl');
	}

	elaboratedJobApplicantDetails() {
		return localStorage.getItem('ElaboratedJobApplicantDetails');
	}

	getAccountTypeString(accountType): string {
		let val = accountType?.toString();
		switch (val) {
			case '1':
			case 'A':
				return 'Asset';
			case '2':
			case 'L':
				return 'Liability';
			case '3':
			case 'X':
			case 'U':
				return 'Unearned Revenue';
			case '4':
			case 'R':
				return 'Revenue';
			case '5':
			case 'E':
				return 'Expense';
		}

		return val;
	}

	getAccountTypeById(accountTypeId) {
		if (accountTypeId == 1) {
			return 'A';
		} else if (accountTypeId == 2) {
			return 'L';
		} else if (accountTypeId == 3) {
			return 'A';
		} else if (accountTypeId == 4) {
			return 'R';
		} else if (accountTypeId == 5) {
			return 'E';
		}
		return;
	}

	approvalConfigNames = [
		'First',
		'Second',
		'Third',
		'Fourth',
		'Fifth',
		'Sixth',
		'Seventh',
		'Eighth',
		'Ninth',
		'Tenth',
		'Eleventh',
		'Twelfth',
		'Thirteenth',
		'Fourteenth',
		'Fifteenth',
	];

	downloaddocument(content, contentType, documentName) {
		let blob = this.base64toBlob(content, contentType);
		let link = document.createElement('a');
		link.href = window.URL.createObjectURL(blob);
		link.download = documentName;
		link.click();
	}

	base64toBlob(base64Data, contentType) {
		contentType = contentType || '';
		let sliceSize = 1024;
		let byteCharacters = atob(base64Data);
		let bytesLength = byteCharacters.length;
		let slicesCount = Math.ceil(bytesLength / sliceSize);
		let byteArrays = new Array(slicesCount);

		for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
			var begin = sliceIndex * sliceSize;
			var end = Math.min(begin + sliceSize, bytesLength);

			var bytes = new Array(end - begin);
			for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
				bytes[i] = byteCharacters[offset].charCodeAt(0);
			}
			byteArrays[sliceIndex] = new Uint8Array(bytes);
		}
		return new Blob(byteArrays, { type: contentType });
	}

	getDateOfAMonthInInt(month, year) {
		let days = [];
		let daysInMonth = moment(`${year}-${month}`, 'YYYY-MM').daysInMonth();
		for (let i = 1; i <= daysInMonth; i++) {
			const date = i;
			days.push(date);
		}
		return days;
	}

	filterParams: {} = {
		comparator: function (filterLocalDateAtMidnight, cellValue) {
			let dateAsString = cellValue;
			let cellDate = null;
			if (dateAsString == null) {
				return -1;
			}
			let dateParts = dateAsString.split('/');
			if (dateAsString.includes('-')) {
				cellDate = moment(dateAsString.split('T')[0]).toDate();
			} else {
				cellDate = new Date(Number(dateParts[2]), Number(dateParts[0]) - 1, Number(dateParts[1]));
			}
			if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
				return 0;
			}
			if (cellDate < filterLocalDateAtMidnight) {
				return -1;
			}
			if (cellDate > filterLocalDateAtMidnight) {
				return 1;
			}
		},
		browserDatePicker: true,
		minValidYear: 2000,
	};

	getMonthOrder() {
		let fiscalMonthStart = this.getFiscalYearMonthBegin();
		let monthOrder = [];

		let current = 1;
		let currentMonth = fiscalMonthStart;
		while (current <= 12) {
			monthOrder.push({ month: currentMonth, index: current });

			if (currentMonth == 12) {
				currentMonth = 1;
			} else {
				currentMonth++;
			}

			current++;
		}

		return monthOrder;
	}

	checkMatchWithOneCentVariance(bankAmount: number, glAmount: number) {
		let isMatched = false;
		let absBankAmount = Math.abs(bankAmount);
		let absGlAmount = Math.abs(glAmount);
		if (absBankAmount == absGlAmount) {
			isMatched = true;
		} else {
			let diff = Math.abs(absBankAmount - absGlAmount);
			if (diff < 0.01) {
				isMatched = true;
			}
		}
		console.log(`bankAmount: ${absBankAmount}, glAmount: ${absGlAmount}, isMatched: ${isMatched}`);
		return isMatched;
	}

	listErrors(form: FormGroup) {
		if (form.invalid) {
			Object.keys(form.controls).forEach((key) => {
				let errors = form.get(key).errors;
				if (errors) {
					Object.keys(errors).map((error) => {
						console.log(`${key} has ${error} error`);
					});
				}
			});
		}
	}

	numericCompareForSorting(valueA, valueB) {
		// Convert the string values to numbers for comparison
		const numA = parseFloat(valueA ?? 0);
		const numB = parseFloat(valueB ?? 0);

		if (numA < numB) {
			return -1;
		}
		if (numA > numB) {
			return 1;
		}
		return 0;
	}

	alphaNumericCompareForSorting(a, b) {
		// check Null
		a = a ?? '';
		b = b ?? '';

		// Check if both invoice numbers have letters
		const aHasLetters = /[a-zA-Z]/.test(a);
		const bHasLetters = /[a-zA-Z]/.test(b);

		if (aHasLetters && bHasLetters) {
			// If both have letters, sort numerically first
			const aNumeric = parseFloat(a);
			const bNumeric = parseFloat(b);
			if (!isNaN(aNumeric) && !isNaN(bNumeric)) {
				if (aNumeric !== bNumeric) {
					return aNumeric - bNumeric;
				}
			}

			// If numerically equal, sort alphabetically
			return a.localeCompare(b, undefined, { sensitivity: 'case' });
		} else if (aHasLetters) {
			// If only 'a' has letters, 'b' comes first
			return 1;
		} else if (bHasLetters) {
			// If only 'b' has letters, 'a' comes first
			return -1;
		} else {
			// If neither has letters, sort numerically
			const aNumeric = parseFloat(a);
			const bNumeric = parseFloat(b);
			if (!isNaN(aNumeric) && !isNaN(bNumeric)) {
				return aNumeric - bNumeric;
			}

			// If neither is numeric, sort alphabetically
			return a.localeCompare(b, undefined, { sensitivity: 'case' });
		}
	}

	isMobileOrTablet(): boolean {
		return this.deviceService.isMobile() || this.deviceService.isTablet();
	}

	isTimeSheetIPRestricted(): boolean {
		let isTimesheetIPRestrictedValue = localStorage.getItem('IsTimesheetIPRestricted');
		return isTimesheetIPRestrictedValue ? isTimesheetIPRestrictedValue == 'true' : false;
	}

	getErrorMessage(err: any): string {
		let errorMessage = '';
		try {
			let error = JSON.parse(err);
			if (typeof error === 'object') {
				errorMessage = '<ul>';
				for (const property in error) {
					if (error.hasOwnProperty('message')) {
						errorMessage = error['message'];
						let errorArray = errorMessage.split('-> ');
						if (Array.isArray(errorArray)) {
							errorMessage = '<ul>';
							errorArray.forEach((errorItem) => {
								errorMessage += '<li>' + errorItem + '</li>';
							});
						}
						break;
					} else {
						let errorArray = error[property];
						if (Array.isArray(errorArray)) {
							errorArray.forEach((errorItem) => {
								errorMessage += '<li>' + errorItem + '</li>';
							});
						}
					}
				}
				if (errorMessage.indexOf('<ul>') >= 0) {
					errorMessage += '</ul>';
				}
			}
		} catch {
			errorMessage = err.error?.message || err.error || err.status || err;
		}
		return errorMessage;
	}

	benefitsEnrollmentMessage() {
		let benefitsEnrollmentMessage = localStorage.getItem('BenefitsEnrollmentMessage');
		return benefitsEnrollmentMessage === null || benefitsEnrollmentMessage == 'null'
			? 'Waive or enroll in benefits by'
			: benefitsEnrollmentMessage;
	}
	getLevelIdentifiers(
		fund: any,
		department: any,
		generalLedgerId: any,
		restrictionLevels: { E: string; O: string; A: string },
		accountDetail: any
	) {
		let levelIdentifiers = {
			E: null,
			O: null,
			A: null,

			fundId: null,
			fundTypeId: null,
			fundGroup: null,
			e4: null,
			e5: null,
			e6: null,

			departmentId: null,
			subFunctionId: null,
			functionId: null,
			o4: null,
			o5: null,
			o6: null,

			glId: null,
			subObjectCodeId: null,
			objectCodeId: null,
			character: null,
			accountType: null,
			a6: null,
		};

		if (restrictionLevels.E) {
			switch (restrictionLevels.E) {
				case 'E1':
					levelIdentifiers.E = fund || null;
					levelIdentifiers.fundId = fund || null;
					break;
				case 'E2':
					levelIdentifiers.E = accountDetail.fundType;
					levelIdentifiers.fundTypeId = accountDetail.fundType;
					break;
				case 'E3':
					levelIdentifiers.E = accountDetail.fundGroup;
					levelIdentifiers.fundGroup = accountDetail.fundGroup;
					break;
				case 'E4':
					levelIdentifiers.E = accountDetail.e4;
					levelIdentifiers.e4 = accountDetail.e4;
					break;
				case 'E5':
					levelIdentifiers.E = accountDetail.e5;
					levelIdentifiers.e5 = accountDetail.e5;
					break;
				case 'E6':
					levelIdentifiers.E = accountDetail.e6;
					levelIdentifiers.e6 = accountDetail.e6;
					break;
			}
		}

		if (restrictionLevels.O) {
			switch (restrictionLevels.O) {
				case 'O1':
					levelIdentifiers.O = department || null;
					levelIdentifiers.departmentId = department || null;
					break;
				case 'O2':
					levelIdentifiers.O = accountDetail.subFunction;
					levelIdentifiers.subFunctionId = accountDetail.subFunction;
					break;
				case 'O3':
					levelIdentifiers.O = accountDetail.function;
					levelIdentifiers.functionId = accountDetail.function;
					break;
				case 'O4':
					levelIdentifiers.O = accountDetail.o4;
					levelIdentifiers.o4 = accountDetail.o4;
					break;
				case 'O5':
					levelIdentifiers.O = accountDetail.o5;
					levelIdentifiers.o5 = accountDetail.o5;
					break;
				case 'O6':
					levelIdentifiers.O = accountDetail.o6;
					levelIdentifiers.o6 = accountDetail.o6;
					break;
			}
		}

		if (restrictionLevels.A) {
			switch (restrictionLevels.A) {
				case 'A1':
					levelIdentifiers.A = generalLedgerId || null;
					levelIdentifiers.glId = generalLedgerId || null;
					break;
				case 'A2':
					levelIdentifiers.A = accountDetail.subObjectCode;
					levelIdentifiers.subObjectCodeId = accountDetail.subObjectCode;
					break;
				case 'A3':
					levelIdentifiers.A = accountDetail.objectCode;
					levelIdentifiers.objectCodeId = accountDetail.objectCode;
					break;
				case 'A4':
					levelIdentifiers.A = accountDetail.accountCharacter;
					levelIdentifiers.character = accountDetail.accountCharacter;
					break;
				case 'A5':
					levelIdentifiers.A = accountDetail.accountType;
					levelIdentifiers.accountType = accountDetail.accountType;
					break;
				case 'A6':
					levelIdentifiers.A = accountDetail.a6;
					levelIdentifiers.a6 = accountDetail.a6;
					break;
			}
		}
		return levelIdentifiers;
	}

	isControlRequiredField(form_field: any) {
		if (!form_field.validator) {
			return false;
		}

		const validator = form_field.validator({} as AbstractControl);
		return validator && validator.required;
	}
	getMileageRate() {
		const mileageRate = localStorage.getItem('ReimbursementMileageRate');
		return mileageRate && mileageRate !== 'null' && !isNaN(parseFloat(mileageRate)) ? parseFloat(mileageRate) : 0.445;
	}

	getSegmentDetails(account) {
		const buildSegmentDetail = (segmentId, segmentsData) => {
			const segment = segmentsData.find((seg) => seg.subSegments.some((subSegment) => subSegment.id === segmentId));
			if (!segment) return '';

			const subSegment = segment.subSegments.find((subSegment) => subSegment.id === segmentId);
			if (!subSegment) return '';

			let detail = `${segment.name}: `;
			if (subSegment.idNumber) {
				detail += `${subSegment.idNumber} - `;
			}
			detail += subSegment.name;

			return detail;
		};

		const segmentDetails = [];
		if (account.segment1Id) {
			const detail = buildSegmentDetail(account.segment1Id, account.segmentsdata);
			if (detail) segmentDetails.push(detail);
		}
		if (account.segment2Id) {
			const detail = buildSegmentDetail(account.segment2Id, account.segmentsdata);
			if (detail) segmentDetails.push(detail);
		}
		if (account.segment3Id) {
			const detail = buildSegmentDetail(account.segment3Id, account.segmentsdata);
			if (detail) segmentDetails.push(detail);
		}

		return segmentDetails.join(', ');
	}

	getProjectDetails(account, projectId) {
		if (account?.projectGrantList) {
			let project = account.projectGrantList?.find((x) => x.projectGrantForm?.id == projectId)?.projectGrantForm;
			if (project) {
				return project.type + ' - ' + project.number + ' - ' + project.title;
			} else {
				return '';
			}
		}
	}

	getProjectTaskDetails(account, projecttaskId) {
		if (account?.projectsGrantsTasks) {
			let project = account.projectsGrantsTasks?.find((x) => x.id == projecttaskId);
			if (project) {
				return project.number + ' - ' + project.title;
			} else {
				return '';
			}
		}
	}

	// This is build for initial purchase request form to get current fiscal year + next 10 available
	getFiscalYears(): number[] {
		const currentFiscalYear = this.getFiscalYearFromDate(new Date());
		const options = [];
		for (let i = 0; i <= 11; i++) {
			// to get current fiscal year + next 10 available so I used 11
			options.push(currentFiscalYear + i);
		}
		return options;
	}

	getParentGLDropdownConfigurations(
		clientId: string
	): Observable<{ hasParentFund: boolean; hasParentDepartment: boolean; hasFSLineItem: boolean }> {
		return this.clientService.getClientFeatures(clientId).pipe(
			map((res) => {
				const hasParentFund = !!res.hasParentFund;
				const hasParentDepartment = !!res.hasParentDepartment;
				const hasFSLineItem = !!res.hasFSLineItem;
				localStorage.setItem('HasParentFund', hasParentFund.toString());
				localStorage.setItem('HasParentDepartment', hasParentDepartment.toString());
				localStorage.setItem('HasFSLineItem', hasFSLineItem.toString());
				return { hasParentFund, hasParentDepartment, hasFSLineItem };
			})
		);
	}
}
