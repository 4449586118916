import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ClientModifyResponse } from '../models/ClientResponse';
import { ClientConfigurationView } from 'app/models/ClientConfigurationView';
import { environment } from 'environments/environment';
import { Client } from 'app/models/Client';
import { AvailableForm } from 'app/models/AvailableForm';
import { Configuration } from 'app/models/Configuration';
import { BudgetConfiguration } from 'app/models/BudgetConfiguration';
import { Contact } from 'app/models/Contact';
import { ClientTaxInformation } from 'app/models/ClientTaxInformation';
import { tap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class ClientsService {
	private readonly baseUrl: string;

	constructor(private readonly httpClient: HttpClient) {
		this.baseUrl = `${environment.apiUrl}clients`;
	}

	getClientList(): Observable<Client[]> {
		return this.httpClient
			.get<Client[]>(`${this.baseUrl}`)
			.pipe(tap((items) => sessionStorage.setItem('clientList', JSON.stringify(items))));
	}

	createClient(item: Client): Observable<ClientModifyResponse> {
		return this.httpClient.post<ClientModifyResponse>(`${this.baseUrl}`, item).pipe();
	}

	getClient(clientId: string): Observable<Client> {
		return this.httpClient.get<Client>(`${this.baseUrl}/${clientId}`).pipe();
	}

	getClientForms(clientId: string, isEnabled?: boolean): Observable<AvailableForm[]> {
		return this.httpClient.get<AvailableForm[]>(`${this.baseUrl}/${clientId}/${isEnabled}/forms/`).pipe();
	}

	getClientConfigurations(clientId: string): Observable<Configuration[]> {
		return this.httpClient.get<Configuration[]>(`${this.baseUrl}/${clientId}/configurations`).pipe();
	}

	getClientFeatures(clientId: string): Observable<ClientConfigurationView> {
		return this.httpClient.get<ClientConfigurationView>(`${this.baseUrl}/${clientId}/clientConfigurations`).pipe();
	}

	updateClient(item: Client): Observable<ClientModifyResponse> {
		return this.httpClient.put<ClientModifyResponse>(`${this.baseUrl}/${item.id}`, item).pipe();
	}

	updateClientConfiguration(clientId: string, item: Configuration): Observable<any> {
		return this.httpClient.put<any>(`${this.baseUrl}/${clientId}/configurations`, item).pipe();
	}

	updateAssignmentConfiguration(clientId: string, item: Configuration): Observable<any> {
		return this.httpClient.put<any>(`${this.baseUrl}/${clientId}/assignment-configurations`, item).pipe();
	}

	updateWorkflowConfiguration(clientId: string, items: Configuration[], formType: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.baseUrl}/${clientId}/workflow-configurations?formType=${formType}`, items)
			.pipe();
	}

	getWorkflowConfiguration(clientId: string, formType: string): Observable<Configuration[]> {
		return this.httpClient
			.get<Configuration[]>(`${this.baseUrl}/${clientId}/workflow-configurations?formType=${formType}`)
			.pipe();
	}

	saveForms(clientId: string, forms: AvailableForm[]): Observable<any> {
		return this.httpClient.put<any>(`${this.baseUrl}/${clientId}/forms`, forms).pipe();
	}

	getClientBudgetConfig(clientId: string): Observable<BudgetConfiguration> {
		return this.httpClient.get<BudgetConfiguration>(`${this.baseUrl}/${clientId}/budget/config`).pipe();
	}

	saveClientBudgetConfig(clientId: string, budgetConfig: BudgetConfiguration): Observable<any> {
		return this.httpClient.put<any>(`${this.baseUrl}/${clientId}/budget/config`, budgetConfig).pipe();
	}

	getClientPOConfig(clientId: string): Observable<any> {
		return this.httpClient.get<any>(`${this.baseUrl}/${clientId}/purchasing/po-config`).pipe();
	}

	saveClientPOConfig(clientId: string, item: any): Observable<any> {
		return this.httpClient.put<any>(`${this.baseUrl}/${clientId}/purchasing/po-config`, item).pipe();
	}

	hasHRModule(clientId: string): Observable<any> {
		return this.httpClient.get<any>(`${this.baseUrl}/${clientId}/has-hr-module`).pipe();
	}

	getClientCashieringConfig(clientId: string): Observable<any> {
		return this.httpClient.get<any>(`${this.baseUrl}/${clientId}/cashiering-config`).pipe();
	}

	saveClientCashieringConfig(clientId: string, item: any): Observable<any> {
		return this.httpClient.put<any>(`${this.baseUrl}/${clientId}/cashiering-config`, item).pipe();
	}

	getDuplicateInvoiceWarningConfiguration(clientId: string): Observable<any> {
		return this.httpClient
			.get<any>(`${this.baseUrl}/${clientId}/purchasing/GetDuplicateInvoiceWarningConfiguration`)
			.pipe();
	}

	saveDuplicateInvoiceWarningConfiguration(clientId: string, item: any): Observable<any> {
		return this.httpClient
			.put<any>(`${this.baseUrl}/${clientId}/purchasing/UpdateDuplicateInvoiceWarningConfiguration`, item)
			.pipe();
	}

	getClientBudgetToleranceConfig(clientId: string): Observable<any> {
		return this.httpClient.get<any>(`${this.baseUrl}/${clientId}/payments/budget-tolerance-config`).pipe();
	}

	saveClientBudgetToleranceConfig(clientId: string, item: any): Observable<any> {
		return this.httpClient.put<any>(`${this.baseUrl}/${clientId}/payments/budget-tolerance-config`, item).pipe();
	}

	getPositivePayConfig(clientId: string): Observable<any> {
		return this.httpClient.get<any>(`${this.baseUrl}/${clientId}/payments/positive-pay-Config`).pipe();
	}

	savePositivePayConfig(clientId: string, item: any): Observable<any> {
		return this.httpClient.put<any>(`${this.baseUrl}/${clientId}/payments/positive-pay-Config`, item).pipe();
	}

	generatePositivePay(clientId: string): Observable<any> {
		return this.httpClient.get<any>(`${this.baseUrl}/${clientId}/payments/generate-positive-pay`).pipe();
	}

	downloadPositivePayFile(clientId: string, fileName: string): Observable<Blob> {
		return this.httpClient.get<Blob>(`${this.baseUrl}/${clientId}/payments/download-positive-pay/${fileName}`, {
			responseType: 'blob' as any,
		});
	}

	updateClientContact(clientId: string, item: Contact): Observable<any> {
		return this.httpClient.put(`${this.baseUrl}/${clientId}/contact`, item).pipe();
	}

	updateClientTaxInformation(clientId: string, item: ClientTaxInformation): Observable<any> {
		return this.httpClient.put(`${this.baseUrl}/${clientId}/tax-information`, item).pipe();
	}

	getClientContact(clientId: string): Observable<Contact> {
		return this.httpClient.get<Contact>(`${this.baseUrl}/${clientId}/contact`).pipe();
	}

	getClientTaxInformation(clientId: string): Observable<ClientTaxInformation> {
		return this.httpClient.get<ClientTaxInformation>(`${this.baseUrl}/${clientId}/tax-information`).pipe();
	}

	createTaxBanditBusiness(clientId: string): Observable<ClientTaxInformation> {
		return this.httpClient.post<ClientTaxInformation>(`${this.baseUrl}/${clientId}/taxbandits/business`, {}).pipe();
	}

	getAchBatchConfig(clientId: string): Observable<any> {
		return this.httpClient.get<any>(`${this.baseUrl}/${clientId}/payments/ach-batch-config`).pipe();
	}

	saveAchBatchConfig(clientId: string, item: any): Observable<any> {
		return this.httpClient.put<any>(`${this.baseUrl}/${clientId}/payments/ach-batch-config`, item).pipe();
	}

	generateAchBatch(clientId: string, sourceIds: string[]): Observable<any> {
		return this.httpClient.post<any>(`${this.baseUrl}/${clientId}/payments/generate-ach-batch`, sourceIds).pipe();
	}

	downloadAchBatchFile(clientId: string, fileName: string): Observable<Blob> {
		return this.httpClient.get<Blob>(`${this.baseUrl}/${clientId}/payments/download-ach-batch/${fileName}`, {
			responseType: 'blob' as any,
		});
	}

	getMileageRate(clientId: string, configurationType: string, defaultRate: any): Observable<number> {
		return this.httpClient.get<number>(
			`${this.baseUrl}/${clientId}/${configurationType}/mileage-rate?defaultRate=${defaultRate}`
		);
	}
	getClientDailyFeesImportConfig(clientId: string): Observable<any> {
		return this.httpClient.get<any>(`${this.baseUrl}/${clientId}/daily-fees-import-config`).pipe();
	}
	saveClientDailyFeesImportConfig(clientId: string, item: any): Observable<any> {
		return this.httpClient.put<any>(`${this.baseUrl}/${clientId}/daily-fees-import-config`, item).pipe();
	}
	getClientInvoiceItemPriceAdjustmentConfig(clientId: string): Observable<any> {
		return this.httpClient.get<any>(`${this.baseUrl}/${clientId}/payments/invoice-item-price-adjustment-config`).pipe();
	}
	saveClientInvoiceItemPriceAdjustmentConfig(clientId: string, item: any): Observable<any> {
		return this.httpClient
			.put<any>(`${this.baseUrl}/${clientId}/payments/invoice-item-price-adjustment-config`, item)
			.pipe();
	}
	getDuplicateVendorWarningConfiguration(clientId: string): Observable<any> {
		return this.httpClient
			.get<any>(`${this.baseUrl}/${clientId}/purchasing/duplicate-vendor-warning-configuration`)
			.pipe();
	}
	saveDuplicateVendorWarningConfiguration(clientId: string, item: any): Observable<any> {
		return this.httpClient
			.put<any>(`${this.baseUrl}/${clientId}/purchasing/duplicate-vendor-warning-configuration`, item)
			.pipe();
	}
}
