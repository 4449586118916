import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { QBClassView } from 'app/models/QBClassView';
import { HelpDeskTypeView } from 'app/models/HelpDeskTypeView';
import { Department } from 'app/models/Department';
import { environment } from 'environments/environment';
import { FundView } from 'app/models/FundView';
import { HRAreaNames } from 'app/models/form/hrAreaNames';
import { PayrollCalendarEntry } from 'app/models/PayrollCalendarEntry';
import { GeneralLedgerView } from 'app/models/GeneralLedgerView';
import { CategoryView } from 'app/models/form/MonthlyTimeSheetView';
import { HRFormNames } from 'app/models/form/HRFormNames';
import { Vendor } from 'app/models/Vendor';
import { PaymentProCoreView } from 'app/models/form/PaymentProCoreView';

@Injectable({
	providedIn: 'root',
})
export class LookupServices {
	constructor(private readonly httpClient: HttpClient) {}

	getUserDepartments(clientId: string, userId: string): Observable<any[]> {
		return this.httpClient.get<Department[]>(`${environment.apiUrl}lookup/departments/${clientId}/${userId}`).pipe();
	}

	getDepartments(clientId: string, onlyActive?: boolean): Observable<Department[]> {
		return this.httpClient
			.get<Department[]>(`${environment.apiUrl}lookup/clients/${clientId}/departments?onlyActive=${onlyActive}`)
			.pipe();
	}

	getDepartmentsForFund(clientId: string, fundId: number): Observable<Department[]> {
		return this.httpClient
			.get<Department[]>(`${environment.apiUrl}lookup/clients/${clientId}/funds/${fundId}/departments`)
			.pipe();
	}

	getVendors(clientId: string): Observable<Vendor[]> {
		return this.httpClient.get<Vendor[]>(`${environment.apiUrl}lookup/clients/${clientId}/vendors`).pipe();
	}

	getVendor(clientId: string, vendorId: string): Observable<Vendor> {
		return this.httpClient.get<Vendor>(`${environment.apiUrl}lookup/clients/${clientId}/vendor/${vendorId}`).pipe();
	}

	getGLAccounts(clientId: string): Observable<GeneralLedgerView[]> {
		return this.httpClient
			.get<GeneralLedgerView[]>(`${environment.apiUrl}lookup/clients/${clientId}/generalledgers`)
			.pipe();
	}

	getGLAccountsCashInBank(clientId: string): Observable<GeneralLedgerView[]> {
		return this.httpClient
			.get<GeneralLedgerView[]>(`${environment.apiUrl}lookup/clients/${clientId}/generalledgersCashInBank`)
			.pipe();
	}

	getFunds(clientId: string, accountType?: string, isTransparency: boolean = false): Observable<FundView[]> {
		return this.httpClient
			.get<FundView[]>(`${environment.apiUrl}lookup/clients/${clientId}/funds?isTransparency=${isTransparency}`)
			.pipe();
	}

	getGLAccountsForDepartment(
		clientId: string,
		departmentId: number,
		accountType?: string,
		isActualOnly?: boolean,
		isBudgetOnly?: boolean
	): Observable<GeneralLedgerView[]> {
		if (!departmentId) {
			departmentId = 0;
		}
		return this.httpClient
			.get<
				GeneralLedgerView[]
			>(`${environment.apiUrl}lookup/clients/${clientId}/departments/${departmentId}/generalledgers?accountType=${accountType}&isActualOnly=${isActualOnly}&isBudgetOnly=${isBudgetOnly}`)
			.pipe();
	}

	getGLAccountsForFund(
		clientId: string,
		fundId: number,
		accountType?: string,
		isActualOnly?: boolean,
		isBudgetOnly?: boolean,
		forBudget?: boolean
	): Observable<GeneralLedgerView[]> {
		if (!fundId) {
			fundId = 0;
		}
		if (!accountType) {
			accountType = '';
		}
		return this.httpClient
			.get<
				GeneralLedgerView[]
			>(`${environment.apiUrl}lookup/clients/${clientId}/funds/${fundId}/generalledgers?accountType=${accountType}&isActualOnly=${isActualOnly}&isBudgetOnly=${isBudgetOnly}&forBudget=${forBudget}`)
			.pipe();
	}

	getGLAccountsForFundAndDepartment(
		clientId: string,
		fundId: number,
		departmentId: number,
		accountType?: string,
		isActualOnly?: boolean,
		isBudgetOnly?: boolean,
		forBudget?: boolean
	): Observable<GeneralLedgerView[]> {
		if (!fundId) {
			fundId = 0;
		}
		if (!departmentId) {
			departmentId = 0;
		}
		if (!accountType) {
			accountType = '';
		}

		return this.httpClient
			.get<
				GeneralLedgerView[]
			>(`${environment.apiUrl}lookup/clients/${clientId}/funds/${fundId}/departments/${departmentId}/generalledgers?accountType=${accountType}&isActualOnly=${isActualOnly}&isBudgetOnly=${isBudgetOnly}&forBudget=${forBudget}`)
			.pipe();
	}

	getDepartmentsForAccount(clientId: string, accountType?: string): Observable<Department[]> {
		return this.httpClient
			.get<Department[]>(`${environment.apiUrl}lookup/clients/${clientId}/departments?accountType=${accountType}`)
			.pipe();
	}

	getHRAreaNames(): Observable<HRAreaNames[]> {
		return this.httpClient.get<HRAreaNames[]>(`${environment.apiUrl}lookup/hr/areas`).pipe();
	}

	getHRFormNames(id: any): Observable<HRFormNames[]> {
		return this.httpClient.get<HRFormNames[]>(`${environment.apiUrl}lookup/hr/areas/${id}`).pipe();
	}

	getPayrollCalendar(year: any, payrollType: string): Observable<PayrollCalendarEntry[]> {
		return this.httpClient
			.get<
				PayrollCalendarEntry[]
			>(`${environment.apiUrl}lookup/payroll-calendar?year=${year}&payPeriodType=${payrollType}`)
			.pipe();
	}

	getQBClasses(clientId: string): Observable<QBClassView[]> {
		return this.httpClient.get<QBClassView[]>(`${environment.apiUrl}lookup/clients/${clientId}/qbclasses`).pipe();
	}

	getFundsFor(clientId: string, departments: number[]): Observable<FundView[]> {
		return this.httpClient
			.post<FundView[]>(`${environment.apiUrl}lookup/clients/${clientId}/fundsFor`, departments)
			.pipe();
	}

	getDayOfWeek(tempDate: Date): Observable<number> {
		return this.httpClient
			.get<number>(`${environment.apiUrl}lookup/dayOfWeek?date=${tempDate.toLocaleString()}`)
			.pipe();
	}

	getHelpDeskTypes(clientId: string): Observable<HelpDeskTypeView[]> {
		return this.httpClient
			.get<HelpDeskTypeView[]>(`${environment.apiUrl}lookup/clients/${clientId}/helpdesktypes`)
			.pipe();
	}

	GetMonthlyTimeSheetCategoryList(): Observable<CategoryView[]> {
		return this.httpClient.get<CategoryView[]>(`${environment.apiUrl}lookup/monthly-timesheet-category`).pipe();
	}

	GetMonthlyTimeSheetSubCategoryList(): Observable<CategoryView[]> {
		return this.httpClient.get<CategoryView[]>(`${environment.apiUrl}lookup/monthly-timesheet-subcategory`).pipe();
	}

	getYears(clientId: string): Observable<number[]> {
		return this.httpClient.get<number[]>(`${environment.apiUrl}lookup/clients/${clientId}/get-years`);
	}

	getMonthsForYear(clientId: string, year: string): Observable<number[]> {
		return this.httpClient.get<number[]>(`${environment.apiUrl}lookup/clients/${clientId}/${year}/get-months-for-year`);
	}

	getPaymentProCoreByAccountCode(clientId: string, accountCode: string): Observable<PaymentProCoreView> {
		return this.httpClient.get<PaymentProCoreView>(
			`${environment.apiUrl}lookup/clients/${clientId}/getPaymentProCoreByAccountCode/${accountCode}`
		);
	}

	deleteForm(clientId: string, formId: string, formType: string) {
		let url = '';
		switch (formType) {
			case 'fieldwork-request':
				url = `${environment.apiUrl}forms/clients/${clientId}/fieldwork-request/${formId}`;
				break;
			case 'budget-amend':
			case 'budget/amendment':
				url = `${environment.apiUrl}forms/clients/${clientId}/budget-forms/amendment/${formId}`;
				break;
			case 'budget-transfer':
			case 'budget/transfer':
				url = `${environment.apiUrl}forms/clients/${clientId}/budget-forms/transfer/${formId}`;
				break;
			case 'ye-request':
				url = `${environment.apiUrl}forms/clients/${clientId}/ye-request/${formId}`;
				break;
			case 'hr-support':
				url = `${environment.apiUrl}forms/clients/${clientId}/hr-support/${formId}`;
				break;
			case 'expense-budget':
				url = `${environment.apiUrl}forms/clients/${clientId}/expense-budget/${formId}`;
				break;
			case 'revenue-budget':
				url = `${environment.apiUrl}forms/clients/${clientId}/revenue-budget/${formId}`;
				break;
			case 'capital-budget':
				url = `${environment.apiUrl}forms/clients/${clientId}/capital-budget/${formId}`;
				break;
			case 'operating-expense-budget':
				url = `${environment.apiUrl}forms/clients/${clientId}/operating-expense-budget/${formId}`;
				break;
			case 'request-form':
				url = `${environment.apiUrl}forms/clients/${clientId}/request-form/${formId}`;
				break;
			case 'support-request':
				url = `${environment.apiUrl}forms/clients/${clientId}/support-request/${formId}`;
				break;
			case 'task-request':
				url = `${environment.apiUrl}forms/clients/${clientId}/task-request/${formId}`;
				break;
			case 'purchasing':
				url = `${environment.apiUrl}forms/clients/${clientId}/purchasing/${formId}`;
				break;
			case 'change-order':
				url = `${environment.apiUrl}forms/clients/${clientId}/change-order/${formId}`;
				break;
			case 'ap-invoice':
				url = `${environment.apiUrl}forms/clients/${clientId}/ap-invoice/${formId}`;
				break;
			case 'check-request':
				url = `${environment.apiUrl}forms/clients/${clientId}/check-request/${formId}`;
				break;
			case 'p-card':
				url = `${environment.apiUrl}forms/clients/${clientId}/p-card/${formId}`;
				break;
			case 'vendor-form':
				url = `${environment.apiUrl}forms/clients/${clientId}/vendor/${formId}`;
				break;
			case 'invoice-approval':
				url = `${environment.apiUrl}forms/clients/${clientId}/invoice-approval/${formId}`;
				break;
			case 'timesheet':
				url = `${environment.apiUrl}forms/clients/${clientId}/timesheet/${formId}`;
				break;
			case 'monthly-timesheet':
				url = `${environment.apiUrl}forms/clients/${clientId}/monthly-timesheet/${formId}`;
				break;
			case 'new-project':
				url = `${environment.apiUrl}forms/clients/${clientId}/new-project/${formId}`;
				break;
			case 'cc-approval':
				url = `${environment.apiUrl}forms/clients/${clientId}/cc-approval/${formId}`;
				break;
			case 'bid-form':
				url = `${environment.apiUrl}forms/clients/${clientId}/bid/${formId}`;
				break;
			case 'license-form':
				url = `${environment.apiUrl}licensing/clients/${clientId}/license/${formId}`;
				break;
			case 'addition-form':
				url = `${environment.apiUrl}capital-assets/clients/${clientId}/additions/${formId}`;
				break;
			case 'depreciation-schedule-form':
				url = `${environment.apiUrl}capital-assets/clients/${clientId}/depreciation-schedule-form/${formId}`;
				break;
			case 'disposal-form':
				url = `${environment.apiUrl}capital-assets/clients/${clientId}/disposals/${formId}`;
				break;
			case 'transfer-form':
				url = `${environment.apiUrl}capital-assets/clients/${clientId}/transfer/${formId}`;
				break;
			case 'receipt-verification':
				url = `${environment.apiUrl}forms/clients/${clientId}/ReceiptVerification/${formId}`;
				break;
			case 'cashiering-report':
				url = `${environment.apiUrl}forms/clients/${clientId}/cashiering-report/${formId}`;
				break;
			case 'journal-entry':
				url = `${environment.apiUrl}general-ledger/clients/${clientId}/forms/journal-entry/${formId}`;
				break;
			case 'project-mgt':
				url = `${environment.apiUrl}forms/clients/${clientId}/project-mgt/${formId}`;
				break;
			case 'void-reissue-form':
				url = `${environment.apiUrl}forms/clients/${clientId}/void-reissue-form/${formId}`;
				break;
			case 'customer-invoice-form':
				url = `${environment.apiUrl}miscellaneous-billing/clients/${clientId}/customer-invoice-form/${formId}`;
				break;
			case 'quick-pay':
				url = `${environment.apiUrl}forms/clients/${clientId}/quick-pay/${formId}`;
				break;
			case 'new-capital-lease-form':
				url = `${environment.apiUrl}capital-lease/clients/${clientId}/forms/new-capital-lease-form/${formId}`;
				break;
			case 'lease-modification-form':
				url = `${environment.apiUrl}capital-lease/clients/${clientId}/forms/lease-modification-form/${formId}`;
				break;
			case 'new-hire-form':
				url = `${environment.apiUrl}hris/clients/${clientId}/employee/${formId}`;
				break;
			case 'implementation-billing-ar':
				url = `${environment.apiUrl}forms/clients/${clientId}/implementation/${formId}`;
				break;
			case 'implementation-general-ledger':
				url = `${environment.apiUrl}forms/clients/${clientId}/implementation-general-ledger/${formId}`;
				break;
			case 'implementation-purchasing':
				url = `${environment.apiUrl}forms/clients/${clientId}/implementation-purchasing/${formId}`;
				break;
			case 'open-close-period':
				url = `${environment.apiUrl}general-ledger/clients/${clientId}/forms/open-close-period/${formId}`;
				break;
			case 'cash-receipt-form':
				url = `${environment.apiUrl}miscellaneous-billing/clients/${clientId}/cash-receipt-form/${formId}`;
				break;
			case 'quick-invoice-form':
				url = `${environment.apiUrl}miscellaneous-billing/clients/${clientId}/quick-invoice-form/${formId}`;
				break;
			case 'implementation-payment':
				url = `${environment.apiUrl}forms/clients/${clientId}/implementation-payment/${formId}`;
				break;
			case 'po-rollover-form':
				url = `${environment.apiUrl}forms/clients/${clientId}/po-rollover-form/${formId}`;
				break;
			case 'contract-approval':
				url = `${environment.apiUrl}forms/clients/${clientId}/contract-approval/${formId}`;
				break;
			case 'project-grant-reopen-form':
				url = `${environment.apiUrl}project-grant/clients/${clientId}/project-grant-reopen-form/${formId}`;
				break;
			case 'project-grant-task':
				url = `${environment.apiUrl}project-grant/clients/${clientId}/project-grant-task/${formId}`;
				break;
			case 'project-grant-close-form':
				url = `${environment.apiUrl}project-grant/clients/${clientId}/project-grant-close-form/${formId}`;
				break;
			case 'project-grant-form':
				url = `${environment.apiUrl}project-grant/clients/${clientId}/project-grant-form/${formId}`;
				break;
			case 'individual-payment-form':
				url = `${environment.apiUrl}forms/clients/${clientId}/individual-payment/${formId}`;
				break;
			case 'lease-evaluation':
				url = `${environment.apiUrl}forms/clients/${clientId}/lease-evaluation/${formId}`;
				break;
			case 'work-order-request':
				url = `${environment.apiUrl}forms/clients/${clientId}/work-order-request/${formId}`;
				break;
			case 'customer-refund-form':
				url = `${environment.apiUrl}forms/clients/${clientId}/customer-refund-form/${formId}`;
				break;
			case 'customer-form':
				url = `${environment.apiUrl}miscellaneous-billing/clients/${clientId}/customer-form/${formId}`;
				break;
			case 'invoice-adjustment-form':
				url = `${environment.apiUrl}miscellaneous-billing/clients/${clientId}/customer-invoice-adjustment-form/${formId}`;
				break;
			case 'implementation-hr':
				url = `${environment.apiUrl}forms/clients/${clientId}/implementation-hr/${formId}`;
				break;
			case 'fund-addition-form':
				url = `${environment.apiUrl}general-ledger/clients/${clientId}/forms/fund-addition/${formId}`;
				break;
			case 'gl-account':
				url = `${environment.apiUrl}general-ledger/clients/${clientId}/forms/accounts/${formId}`;
				break;
			case 'reclassification-form':
				url = `${environment.apiUrl}capital-assets/clients/${clientId}/reclassification/${formId}`;
				break;
			case 'complainant-form':
				url = `${environment.apiUrl}code-enforcement/clients/${clientId}/complainant-form/${formId}`;
				break;
			case 'time-off-request-form':
				url = `${environment.apiUrl}hris/clients/${clientId}/time-off/${formId}`;
				break;
			case 'benefit-enrolment-form':
				url = `${environment.apiUrl}hris/clients/${clientId}/benefit-enrolment-form/${formId}`;
				break;
			case 'travel-pre-approval':
				url = `${environment.apiUrl}travel-pre-appproval/clients/${clientId}/forms/travel-pre-appproval-form/${formId}`;
				break;
			case 'travel-reimbursement':
				url = `${environment.apiUrl}travel-reimbursement/clients/${clientId}/forms/travel-reimbursement-form/${formId}`;
				break;
			case 'employee-action-form':
				url = `${environment.apiUrl}hris/clients/${clientId}/employee-action-form/${formId}`;
				break;
			case 'job-application':
				url = `${environment.apiUrl}hris/clients/${clientId}/job-application-workflow/${formId}`;
				break;
			case 'workflow-group-form':
				url = `${environment.apiUrl}workflow-management/clients/${clientId}/workflow-group/${formId}`;
				break;
			case 'individual-payee-form':
				url = `${environment.apiUrl}forms/clients/${clientId}/individual-payee-form/${formId}`;
				break;
			case 'announcement-form':
				url = `${environment.apiUrl}announcement/clients/${clientId}/${formId}`;
				break;
			case 'leave-balance-adjustment':
				url = `${environment.apiUrl}hris/clients/${clientId}/leave-balance/${formId}`;
				break;
			case 'funding-increase-form':
				url = `${environment.apiUrl}forms/clients/${clientId}/project-fund-increase-form/${formId}`;
				break;
			case 'payment-approval-form':
				url = `${environment.apiUrl}forms/clients/${clientId}/payment-approval-form/${formId}`;
				break;
			case 'project-close-form':
				url = `${environment.apiUrl}forms/clients/${clientId}/project-close-form/${formId}`;
				break;
			case 'project-open-form':
				url = `${environment.apiUrl}forms/clients/${clientId}/project-open-form/${formId}`;
				break;
			case 'fiserv-refund-form':
				url = `${environment.apiUrl}forms/clients/${clientId}/fiserv-refund-form/${formId}`;
				break;
			case 'individual-refund-form':
				url = `${environment.apiUrl}forms/clients/${clientId}/individual-refund-form/${formId}`;
				break;
			case 'business-license':
				url = `${environment.apiUrl}licensing/clients/${clientId}/license/${formId}`;
				break;
			case 'tuition-reimbursement-pre-approval':
				url = `${environment.apiUrl}forms/clients/${clientId}/tution-reimb-pre-approval/${formId}`;
				break;
			case 'tuition-reimbursement':
				url = `${environment.apiUrl}forms/clients/${clientId}/tuition-reimbursement/${formId}`;
				break;
			case 'tuition-reimbursement-payment':
				url = `${environment.apiUrl}forms/clients/${clientId}/tuition-reimbursement-payment/${formId}`;
				break;
			case 'department-addition-form':
				url = `${environment.apiUrl}general-ledger/clients/${clientId}/forms/department-addition/${formId}`;
				break;
			case 'investment-income-allocation-form':
				url = `${environment.apiUrl}forms/clients/${clientId}/investment-income-allocation-form/${formId}`;
				break;
			case 'go-live-approval-form':
				url = `${environment.apiUrl}forms/clients/${clientId}/go-live-approval-form/${formId}`;
				break;
			case 'user-form':
				url = `${environment.apiUrl}user-management/clients/${clientId}/forms/user-form/${formId}`;
				break;
			case 'location-form':
				url = `${environment.apiUrl}inventory/clients/${clientId}/location/${formId}`;
				break;
			case 'item-form':
				url = `${environment.apiUrl}inventory/clients/${clientId}/item/${formId}`;
				break;
			case 'receiving-form':
				url = `${environment.apiUrl}inventory/clients/${clientId}/receiving-form/${formId}`;
				break;
			case 'usage-form':
				url = `${environment.apiUrl}inventory/clients/${clientId}/usage-form/${formId}`;
				break;
			case 'new-customer-form':
				url = `${environment.apiUrl}utility-billing/clients/${clientId}/customer-form/${formId}`;
				break;
			case 'new-property-form':
				url = `${environment.apiUrl}utility-billing/clients/${clientId}/property-form/${formId}`;
				break;
			case 'new-customer-account-form':
				url = `${environment.apiUrl}utility-billing/clients/${clientId}/customer-account-form/${formId}`;
				break;

			case 'meter-turn-on-form':
				url = `${environment.apiUrl}utility-billing/clients/${clientId}/meter-turn-on-form/${formId}`;
				break;
			case 'meter-turn-off-form':
				url = `${environment.apiUrl}utility-billing/clients/${clientId}/meter-turn-off-form/${formId}`;
				break;
			case 'manual-meter-read-form':
				url = `${environment.apiUrl}utility-billing/clients/${clientId}/manual-meter-read-form/${formId}`;
				break;
			case 'timesheet-approval-form':
				url = `${environment.apiUrl}hris/clients/${clientId}/timesheet-approval-form/${formId}`;
				break;
			case 'performance-evaluation':
				url = `${environment.apiUrl}hris/clients/${clientId}/performance-evaluation/${formId}`;
				break;
			case 'special-pay-form':
				url = `${environment.apiUrl}hris/clients/${clientId}/special-pay-form/${formId}`;
				break;
			case 'project-mgt-2':
				url = `${environment.apiUrl}forms/clients/${clientId}/project-mgt/${formId}`;
				break;
			case 'mass-pay-rate-change-form':
				url = `${environment.apiUrl}hris/clients/${clientId}/forms/mass-pay-rate-change-form/${formId}`;
				break;
			case 'adjustment-form':
				url = `${environment.apiUrl}inventory/clients/${clientId}/adjustment-form/${formId}`;
				break;
			case 'implementation-payroll':
				url = `${environment.apiUrl}forms/clients/${clientId}/implementation-payroll/${formId}`;
				break;
			case 'tax-distribution':
				url = `${environment.apiUrl}forms/clients/${clientId}/tax-dist/${formId}`;
				break;
			case 'transaction-assignment-form':
				url = `${environment.apiUrl}project-grant/clients/${clientId}/project-grant-linkage-form/${formId}`;
				break;
			case 'leave-balance-payout-form':
				url = `${environment.apiUrl}hris/clients/${clientId}/leave-balance-payout-form/${formId}`;
				break;
			case 'payment-plan-form':
				url = `${environment.apiUrl}utility-billing/clients/${clientId}/payment-plan-form/${formId}`;
				break;
			case 'meter-change-out-form':
				url = `${environment.apiUrl}utility-billing/clients/${clientId}/meter-change-out-form/${formId}`;
				break;
			case 'payroll-adjustment-form':
				url = `${environment.apiUrl}hris/clients/${clientId}/forms/payroll-adjustment-form/${formId}`;
				break;
			case 'bank-reconciliation':
				url = `${environment.apiUrl}general-ledger/clients/${clientId}/forms/bank-rec/${formId}`;
				break;
			case 'other-bank-reconciliation':
				url = `${environment.apiUrl}general-ledger/clients/${clientId}/forms/other-bank-rec/${formId}`;
				break;
			case 'special-event-permit-application-form':
				url = `${environment.apiUrl}recreation/clients/${clientId}/forms/special-event-permit-application-form/${formId}`;
				break;
			case 'other-tuition-reimbursement':
				url = `${environment.apiUrl}forms/clients/${clientId}/other-tuition-reimbursement/${formId}`;
				break;
			case 'other-tuition-reimbursement-payment':
				url = `${environment.apiUrl}forms/clients/${clientId}/other-tuition-reimbursement-payment/${formId}`;
				break;
			case 'customer-credit-memos':
				url = `${environment.apiUrl}miscellaneous-billing/clients/${clientId}/customer-credit-memo-form/${formId}`;
				break;
			case 'vendor-credit-memos':
				url = `${environment.apiUrl}forms/clients/${clientId}/vendor-credit-memo-form/${formId}`;
				break;
			case 'multiple-vendor-payment':
				url = `${environment.apiUrl}forms/clients/${clientId}/multiple-vendor-payment/${formId}`;
				break;
			case 'personnel-budget-form':
				url = `${environment.apiUrl}forms/clients/${clientId}/personnel-budget/${formId}`;
				break;
			case 'jury-check-registry-form':
				url = `${environment.apiUrl}forms/clients/${clientId}/jury-check-registry-form/${formId}`;
				break;
			case 'cash-bond-payment-form':
				url = `${environment.apiUrl}forms/clients/${clientId}/cash-bond-payment-form/${formId}`;
				break;
			case 'out-of-county-travel':
				url = `${environment.apiUrl}forms/clients/${clientId}/out-of-county-travel/${formId}`;
				break;
			case 'restitution-payment-form':
				url = `${environment.apiUrl}forms/clients/${clientId}/restitution-payment-form/${formId}`;
				break;
			case 'other-reimbursable-expenses':
				url = `${environment.apiUrl}forms/clients/${clientId}/other-reimbursable-expenses/${formId}`;
				break;
			case 'customer-feedback-survey':
				url = `${environment.apiUrl}forms/clients/${clientId}/feedback-survey/${formId}`;
				break;
			case 'field-payment-request-form':
				url = `${environment.apiUrl}forms/clients/${clientId}/field-payment-request-form/${formId}`;
				break;
			case 'cash-bond-form':
				url = `${environment.apiUrl}forms/clients/${clientId}/cash-bond-form/${formId}`;
				break;
			case 'multiple-payment':
				url = `${environment.apiUrl}forms/clients/${clientId}/multiple-payment/${formId}`;
				break;
			case 'initial-purchase-request-form':
				url = `${environment.apiUrl}forms/clients/${clientId}/initial-purchase-request-form/${formId}`;
				break;
			case 'daily-fees-import-form':
				url = `${environment.apiUrl}forms/clients/${clientId}/daily-fees-import-form/${formId}`;
				break;
			case 'employee-reimbursable-expenses':
				url = `${environment.apiUrl}forms/clients/${clientId}/employee-reimbursable-expenses/${formId}`;
				break;
		}
		return this.httpClient.delete(url).pipe();
	}

	getPaymentProcessors() {
		return ['Heartland', 'NMI', 'Open Edge', 'PaySafe', 'Stripe', 'Spot On'];
	}

	getPaymentModes() {
		return ['Cash', 'Check', 'Credit Card', 'Debit Card', 'Electronic/ACH'];
	}

	getClientName(clientId: string): { name: string; abbreviation: string } {
		switch (clientId) {
			case environment.hillboroughCountyTaxCollector:
				return {
					name: 'Hillsborough County Tax Collector',
					abbreviation: 'HCTC',
				};
			case environment.osceolaClerk:
				return {
					name: 'Osceola County Clerk',
					abbreviation: 'OCC',
				};
			case environment.palmBeachTransportationPlanningAgency:
				return {
					name: 'Palm Beach Transportation Planning Agency',
					abbreviation: 'TPA',
				};
			default:
				return {
					name: '',
					abbreviation: 'City',
				};
		}
	}
}
