import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { BaseFormService } from '../BaseFormService';
import { UtilityService } from '../utility.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root',
})
export class RestitutionPaymentFormService extends BaseFormService {
	constructor(httpClient: HttpClient, utilityService: UtilityService) {
		super(`${environment.apiUrl}forms/clients/$$clientId$$/restitution-payment-form`, httpClient, utilityService);
	}

	submitForApproval(clientId: string, id: any, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.getUrl(clientId)}/${id}/approval`, {
				userId,
			})
			.pipe();
	}
	export(clientId: string, ids: string[]): Observable<any> {
		return this.httpClient
			.post(`${this.getUrl(clientId)}/export`, ids, {
				responseType: 'blob',
			})
			.pipe();
	}
	downloadSampleFileForPaymentDetail(clientId: string): Observable<any> {
		return this.httpClient
			.get(`${this.getUrl(clientId)}/download-sample-file`, {
				responseType: 'blob',
			})
			.pipe();
	}
	createFormWithPayeeAndFormDocuments(clientId: string, request: any): Observable<any> {
		return this.httpClient.post<any>(`${this.getUrl(clientId)}`, this.setupRequestWithDocument(request)).pipe();
	}

	updateFormWithPayeeAndFormDocuments(clientId: string, formId: any, request: any): Observable<any> {
		return this.httpClient
			.put<any>(`${this.getUrl(clientId)}/${formId}`, this.setupRequestWithDocument(request))
			.pipe();
	}

	setupRequestWithDocument(request: any): FormData {
		const formData: FormData = new FormData();
		var detailPayeeFiles = [];
		var otherFiles = [];
		for (var i = 0; i < request.attachments.length; i++) {
			if (request.attachments[i]?.file?.label?.includes('Payee')) {
				detailPayeeFiles.push(request.attachments[i]);
			} else {
				otherFiles.push(request.attachments[i]);
			}
		}
		for (let i = 0; i < request.restitutionPaymentFormDetails.length; i++) {
			if (request.restitutionPaymentFormDetails[i].attachments) {
				for (let j = 0; j < request.restitutionPaymentFormDetails[i].attachments.length; j++) {
					detailPayeeFiles.push(request.restitutionPaymentFormDetails[i].attachments[j]);
				}
			}
		}
		if (detailPayeeFiles) {
			for (let i = 0; i < detailPayeeFiles.length; i++) {
				const name = `${detailPayeeFiles[i].file.label}`;
				formData.append(name, detailPayeeFiles[i].file);
				detailPayeeFiles[i].name = name;
			}
		}
		for (let i = 0; i < otherFiles.length; i++) {
			const name = `attachment-${i + 1}`;
			formData.append(name, otherFiles[i].file);
			otherFiles[i].name = name;
		}
		formData.append('model', JSON.stringify(request));
		return formData;
	}
}
