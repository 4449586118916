import { Component, EventEmitter, Input, OnInit, Output, OnChanges } from '@angular/core';

import Swal from 'sweetalert2';
import { WorkflowForm } from 'app/models/WorkflowForm';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilityService } from 'app/services/utility.service';
import { LookupServices } from 'app/services/lookup.service';
import { NotificationService } from 'app/services/notification.service';
import { UserService } from 'app/services/user.service';
import { error } from 'jquery';

@Component({
	selector: 'client-workflow-approval',
	templateUrl: './workflow-approval.component.html',
	styleUrls: ['./workflow-approval.component.css'],
})
export class WorkflowApprovalComponent implements OnInit, OnChanges {
	hideApproval: boolean;
	currentWorkflow: any;
	hasAlreadyApproved: any;
	disableRejectionButton: any;
	rejectionReasonRequired: boolean;
	rejectionReasonValid: Boolean;
	rejectionReason: any;
	private isRejected: boolean;
	isSearchForm: any;
	defaultText = 'Are you sure you want to approve this form?';
	canSkipApprover: boolean = false;
	isOwner: boolean;
	isAdmin: boolean = false;
	isCurrentApprover: boolean = false;
	@Input() clientId: string;
	@Input() workflow: WorkflowForm;
	@Input() userId: string;
	@Input() canApprove: boolean;
	@Input() formId: string;
	@Output() onApproveWorkflow: EventEmitter<any> = new EventEmitter<any>();
	@Output() onRejectWorkflow: EventEmitter<any> = new EventEmitter<any>();
	@Input() hideDelete: boolean = false;
	@Input() hideDecline: boolean = false;
	@Input() hideTitle: boolean = false;
	@Input() canSendBack: boolean = false;
	@Input() disabledCanSendBackBtn: boolean = false;
	@Output() sendBack: EventEmitter<any> = new EventEmitter<any>();
	@Input() formType: string;
	isButtonDisabled = false;
	constructor(
		private activatedRoute: ActivatedRoute,
		private utilityService: UtilityService,
		private userService: UserService,
		private lookupService: LookupServices,
		private notificationService: NotificationService,
		private router: Router
	) {
		this.isSearchForm = this.activatedRoute.snapshot.queryParamMap.get('form-search');
		this.isAdmin = this.userService.isAdmin();
	}

	ngOnInit(): void {
		this.checkPermission();
	}

	checkPermission() {
		let user = this.userService.currentUserValue.user;
		if (this.workflow?.workflowName && !this.formType) {
			this.formType = this.userService.getFormKeyByName(this.workflow.workflowName);
		}
		if (user.claims && user.claims[this.clientId] && user.claims[this.clientId]) {
			this.canSkipApprover =
				user.claims[this.clientId]?.claims?.find((x) => x.id == this.formType)?.skipApprover ?? false;
		}
		this.isOwner = this.isFormOwner();
		this.isCurrentApprover = this.isApprover();
	}

	// DO NOT REMOVE THIS METHOD
	ngOnChanges() {
		this.isRejected = this.workflow?.declinedReason != null && this.workflow?.declinedReason?.length > 0;
		this.hideApproval = this.isRejected;

		if (this.workflow?.workflowSteps) {
			for (let step of this.workflow?.workflowSteps) {
				if (step.isCurrent) {
					this.currentWorkflow = step;
				}
			}
		}
		this.checkPermission();
	}

	approveWorkflow(isSkipApprover: boolean) {
		if (isSkipApprover == false) {
			this.isButtonDisabled = true;
		}
		if (this.workflow?.currentApprovers?.length > 0) {
			if (this.workflow?.currentApprovers.find((x) => x.userId == this.userId) === undefined) {
				this.defaultText =
					'Are you sure you want to approve this form on the behalf of ' + this.workflow?.currentApprovers[0].name;
			}
		}

		if (isSkipApprover) {
			this.defaultText = `Are you sure you want to Skip Approval on behalf of ${this.workflow?.currentApprovers[0].name}?`;
		}

		Swal.fire({
			title: 'Are you sure?',
			text: this.defaultText,
			type: 'warning',
			showCancelButton: true,
			confirmButtonText: 'I am sure!',
			cancelButtonText: 'No, cancel it!',
			confirmButtonClass: 'btn btn-success',
			cancelButtonClass: 'btn btn-danger',
			buttonsStyling: false,
		}).then((result) => {
			if (result.value) {
				this.rejectionReasonRequired = false;
				this.onApproveWorkflow.emit(() => {
					Swal.fire({
						title: 'Approved!',
						text: 'This form has been approved.',
						type: 'success',
						confirmButtonClass: 'btn btn-success',
						buttonsStyling: false,
					}).then();

					if (this.isSearchForm == '1') {
						window.opener = self;
						window.close();
					}

					this.utilityService.scrollToTop();
				});
			} else {
				Swal.fire({
					title: 'Cancelled',
					text: 'This form has not been approved.',
					type: 'error',
					confirmButtonClass: 'btn btn-info',
					buttonsStyling: false,
				}).then();
			}
			this.isButtonDisabled = false;
		});
	}

	rejectWorkflow() {
		this.rejectionReasonRequired = true;
		if (this.rejectionReasonValid && this.rejectionReason.length > 0) {
			Swal.fire({
				title: 'Are you sure?',
				text: 'Are you sure you want to decline this form?',
				type: 'warning',
				showCancelButton: true,
				confirmButtonText: 'I am sure!',
				cancelButtonText: 'No, cancel it!',
				confirmButtonClass: 'btn btn-success',
				cancelButtonClass: 'btn btn-danger',
				buttonsStyling: false,
			}).then((result) => {
				if (result.value) {
					const val = {
						reason: this.rejectionReason,
						workflowId: this.currentWorkflow?.id,
						callback: () => {
							Swal.fire({
								title: 'Declined!',
								text: 'This form has been declined.',
								type: 'success',
								confirmButtonClass: 'btn btn-success',
								buttonsStyling: false,
							}).then();

							if (this.isSearchForm == '1') {
								window.opener = self;
								window.close();
							}
						},
					};
					this.onRejectWorkflow.emit(val);
				} else {
					Swal.fire({
						title: 'Cancelled',
						text: 'This form was not declined.',
						type: 'error',
						confirmButtonClass: 'btn btn-info',
						buttonsStyling: false,
					}).then();
				}
			});
		} else {
			this.disableRejectionButton = true;
		}
	}

	rejectionReasonChanged() {
		this.rejectionReasonValid = this.rejectionReason && this.rejectionReason.length > 0;
		if (this.rejectionReasonValid) {
			this.disableRejectionButton = false;
		}
	}

	isApprover() {
		if (this.workflow != null) {
			return this.utilityService.isApprover(this.workflow, this.userId);
		}
		return false;
	}

	deleteForm() {
		Swal.fire({
			title: 'Are you sure?',
			text: 'Are you sure you want to delete this form?',
			type: 'warning',
			showCancelButton: true,
			confirmButtonText: 'I am sure!',
			cancelButtonText: 'No, cancel it!',
			confirmButtonClass: 'btn btn-success',
			cancelButtonClass: 'btn btn-danger',
			buttonsStyling: false,
		}).then((result) => {
			if (result.value) {
				this.lookupService.deleteForm(this.clientId, this.formId, this.formType).subscribe(
					() => {
						this.notificationService.showSuccess('Form has been disabled', 'Success');
						this.router.navigate(['dashboard']).then();
					},
					(error) => {
						this.notificationService.showError(error);
					}
				);
			} else {
				Swal.fire({
					title: 'Cancelled',
					text: 'This form has not been deleted.',
					type: 'error',
					confirmButtonClass: 'btn btn-info',
					buttonsStyling: false,
				}).then();
			}
		});
	}

	sendBackToAddressComments() {
		Swal.fire({
			title: 'Are you sure?',
			text: 'Are you sure you want to send back to address comments?',
			type: 'warning',
			showCancelButton: true,
			confirmButtonText: 'I am sure!',
			cancelButtonText: 'No, cancel it!',
			confirmButtonClass: 'btn btn-success',
			cancelButtonClass: 'btn btn-danger',
			buttonsStyling: false,
		}).then((result) => {
			if (result.value) {
				this.sendBack.emit();
			} else {
				Swal.fire({
					title: 'Cancelled',
					text: 'Send back to address action cancelled.',
					type: 'error',
					confirmButtonClass: 'btn btn-info',
					buttonsStyling: false,
				}).then();
			}
		});
	}

	isFormOwner() {
		if (this.workflow != null) {
			return this.workflow.createdBy == this.userService.currentUserValue.user.id;
		}
		return false;
	}
}
