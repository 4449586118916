import { Component, OnInit, OnDestroy, ViewChild, HostListener } from '@angular/core';
import { NavbarComponent } from '../../shared/navbar/navbar.component';
import { Location, PopStateEvent } from '@angular/common';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import PerfectScrollbar from 'perfect-scrollbar';
import { filter } from 'rxjs/operators';
import { SidebarComponent } from 'app/sidebar/sidebar.component';

@Component({
	selector: 'app-layout',
	templateUrl: './admin-layout.component.html',
})
export class AdminLayoutComponent implements OnInit {
	// url: string;
	url: string;
	location: Location;
	private lastPoppedUrl: string;
	private yScrollStack: number[] = [];
	@ViewChild('sidebar', { static: false }) sidebar;
	@ViewChild(SidebarComponent, { static: false }) sideBar: SidebarComponent;
	constructor(private router: Router, location: Location) {
		this.location = location;
	}

	ngOnInit() {
		const elemMainPanel = <HTMLElement>document.querySelector('.main-panel');
		const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
		this.location.subscribe((ev: PopStateEvent) => {
			this.lastPoppedUrl = ev.url;
		});
		this.router.events.subscribe((event: any) => {
			if (event instanceof NavigationStart) {
				if (event.url != this.lastPoppedUrl) this.yScrollStack.push(window.scrollY);
			} else if (event instanceof NavigationEnd) {
				if (event.url == this.lastPoppedUrl) {
					this.lastPoppedUrl = undefined;
					window.scrollTo(0, this.yScrollStack.pop());
				} else window.scrollTo(0, 0);
			}
		});

		this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
			elemMainPanel.scrollTop = 0;
			elemSidebar.scrollTop = 0;
			this.sideBar.sidebarClose();
		});

		const html = document.getElementsByTagName('html')[0];
		if (window.matchMedia('(min-width: 960px)').matches && !this.isMac()) {
			let ps = new PerfectScrollbar(elemMainPanel);
			ps = new PerfectScrollbar(elemSidebar);
			html.classList.add('perfect-scrollbar-on');
		} else {
			html.classList.add('perfect-scrollbar-off');
		}
	}
	public isMap() {
		if (this.location.prepareExternalUrl(this.location.path()) == '#/maps/fullscreen') {
			return true;
		} else {
			return false;
		}
	}
	isMac(): boolean {
		let bool = false;
		if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
			bool = true;
		}
		return bool;
	}
}
